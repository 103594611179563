import React, { useContext, useEffect, useState } from 'react';
import { AlumnosContext } from './../../../context/alumnos/AlumnosContext';
import { UserContext } from './../../../context/users/UserContext';
import FormInput from './../../FormInput';
import { arrayMeses } from '../../../utilities/utils';

export default function Botonera({ asistencia, pagos, id }) {
	const [switcher, setSwitcher] = useState(false);
	const alumnoCtx = useContext(AlumnosContext);
	const userCtx = useContext(UserContext);

	const { handleChange, authStatus } = userCtx;
	const { handleFaltas, handlePagos, handleChangeAlumno, data } = alumnoCtx;

	const handleClick = () => {
		setSwitcher(!switcher);

		asistencia && handleFaltas(id);
		pagos && handlePagos(id, data.pago, data.mesPago2025);
	};

	useEffect(() => {
		switcher &&
			setTimeout(() => {
				setSwitcher(false);
			}, 21600000);
	}, [switcher]);

	return (
		<>
			{asistencia && (
				<button
					onClick={handleClick}
					disabled={switcher}
					className={` btn btn-warning mr-2`}
				>
					Presente
				</button>
			)}
			{pagos && (
				<>
					<select
						className='form-select mr-2 w-25'
						name='mesPago2025'
						onChange={(e) => {
							authStatus ? handleChangeAlumno(e) : handleChange(e);
						}}
					>
						{arrayMeses.map((mes, index) => {
							return (
								<option key={index} value={mes}>
									{mes}
								</option>
							);
						})}
					</select>
					<FormInput tipo='pago' noLabel />
					<button onClick={handleClick} className=' btn btn-warning mr-2'>
						Pagar
					</button>
				</>
			)}
		</>
	);
}
